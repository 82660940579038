



















import { Component, Vue } from "vue-property-decorator";
import BazzarApi from "@/api/bazaar.api";
import { countdown, observer } from "@/utils/common.util";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import InteractionLib from "@/utils/interaction.lib";
import { set } from "vue/types/umd";
@Component({})
export default class TimitWelfare extends Vue {
  Data: any = null;
  globalState = getModule(GlobalStateStore);
  countdown: any = null;
  Timer: any = null;
   async toGet() {
    InteractionLib.getUserInfo();
    setTimeout(async () => {
      if (this.globalState.userToken) {
        const res = await BazzarApi.getNewPeopleState();
        InteractionLib.closePop();
        if (res) {
          return InteractionLib.openWebView(
            `${location.origin}/timit-welfare-page`
          );
        } else {
          alert("本活动仅限新用户参与");
        }
      }
    }, 100);

    InteractionLib.login();
    //用户未登录 ，调取登录弹窗
  }

  ifToGet() {
    if (this.globalState.userToken) {
      InteractionLib.closePop();
      return InteractionLib.openWebView(
        `${location.origin}/timit-welfare-page`
      );
    }
  }
  mounted() {
    document.querySelector("html")?.classList.remove("__theme_white");
    observer("refreshInit", async () => {
      this.ifToGet();
    });
  }
  NewPeopleState: any = null;
  async created() {
    this.Data = await BazzarApi.getRegisteredWelfare();
    this.NewPeopleState = await BazzarApi.getNewPeopleState();
    const t = Math.ceil(new Date().getTime() / 1000) + this.Data.countdown;
    this.Timer = setInterval(() => {
      const { day, hour, minute, second } = countdown(t);
      let hours = hour;
      if (Number(day) > 0) {
        hours = String(Number(hour) + 24 * Number(day));
      }

      this.countdown = `
                <div class="count-left-15">
                <span class="countdown-span">${hours}</span><span class="count-mao">:</span><span class="countdown-span">${minute}</span><span class="count-mao">:</span><span class="countdown-span">${second}</span>
                </div>
        `;
    }, 1000);
  }
  close() {
    InteractionLib.closePop();
  }
}
